.navigation {
  $root: &;

  @media (min-width: $screen-desktop-sm) {
    position: relative;
    min-height: 20px;

    &__list {
      @include clearList();
      display: flex;
      justify-content: space-around;
      align-items: normal;
      width: 75%;

      &--width_auto {
        display: inline-block;
      }
    }

    &__link {
      position: relative;
    }

    &__link--sub {
      display: flex;
    }

    &__link-name {
      display: block;
      padding: 5px 10px;
      transition: border .2s, color .2s;
      color: $navigationElementColor;
      font-family: $fontFamily;
      font-size: $navigationLinkFontSize;
      font-weight: $navigationLinkFontWeight;
      line-height: 1.2;
      text-decoration: none;
      white-space: nowrap;
      text-align: center;
      position: relative;

      &:visited {
        color: $textBaseColor;
      }
    }

    &__link--all {
      box-shadow: 0 -2px 10px 0 rgb(0 0 0 / .1);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 200px;
      text-align: center;
    }

    &__flyout {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: -2000px;
      right: 0;
      width: 100%;
      transition: visibility .2s, opacity .2s;
      min-height: 200px;
      background: $backgroundWhiteColor;
      box-shadow: 0 5px 25px 0 rgb(0 0 0 / .2);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      z-index: $indexHeader;

      &--all {
        left: 0;
        right: auto;
        width: auto;
      }
    }

    &__element {
      display: inline-block;
      padding: 5px 0;
      border-bottom: $navigationElementBorderBottom;
      transition: border-bottom-color .2s linear;

      &:hover {
        color: $navigationElementHoverColor;
        border-bottom: $navigationElementHoverBorderBottom;

        #{$root} {
          &__link {
            color: $navigationElementHoverColor;
          }

          &__flyout {
            visibility: visible;
            opacity: 1;
          }
        }
      }

      &--hover,
      &--visible,
      &--sub-visible {
        #{$root} {
          &__flyout {
            visibility: visible;
            opacity: 1;
            top: 100%;
          }
        }
      }
    }
  }
}
