@import '../../dependencies.scss';
@import '../../../bps/components/productTeaser/productTeaser.scss';

.product-teaser {
  $root: &;
  $TeaserWideHeadlineSizeMobile: 22px;
  $TeaserWideHeadlineSizeDesktop: 24px;

  &-wide {
    & &__header {
      font-size: $TeaserWideHeadlineSizeMobile;

      @media (min-width: $screen-tablet-portrait) {
        font-size: $TeaserWideHeadlineSizeDesktop;
      }
    }

    & &__subline {
      font-size: $baseFontSize;
    }

    & &__price .price-info__current {
      font-size: $fontSizeXXLarge;

      @media (min-width: $screen-laptop) {
        font-size: $fontSizeXXLarge;
      }

      .price-info__value {
        font-size: 1em;
      }
    }

    @media (min-width: $screen-laptop) {
      & &__box {
        max-width: max(340px, calc(100% - 832px)) !important;
        width: auto;
        box-sizing: border-box;
      }

      &__img {
        object-fit: cover;
      }
    }
  }

  &__img-wrapper {
    &--product {
      img {
        margin: 16px auto;
      }
    }
  }

  &__price {
    max-width: 50%;
    font-size: $fontSizeXSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeXSmall;
    }

    .price-info__current {
      font-size: $fontSizeXXLarge;
    }
  }
}
