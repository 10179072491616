.burger-menu {
  width: 310px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-510px);
  transition: transform 500ms;
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  z-index: $indexBurgerMenu;
  background: $backgroundWhiteColor;
  padding-top: 55px;

  &--open {
    transform: translateX(0);
  }

  &__close-button {
    display: block;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5;
    padding: 15px;
    background: $backgroundWhiteColor;

    @include iconFont(close) {
      color: $textBaseColor;
      font-size: $fontSizeXXMedium;
    }
  }

  &__nav-wrapper {
    margin-top: -50px;
  }

  &__loader {
    @include spinner();
    animation: fadeIn .5s;
    animation-delay: 20ms;
  }
}

// Placeholder style
/* stylelint-disable-next-line */
burger-menu,
[is="burger-menu"] {
  display: none;
}
