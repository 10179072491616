.product-tags {
  .tags {
    &__list {
      display: block;
      float: none;
      text-align: right;
    }
  }

  .tag {
    @include tagOnProduct();
    margin: 0 0 6px;
  }
}
