.page-main {
  display: block;
  min-height: 140px;

  .default-layout & {
    padding: 0;
  }
}

body,
html {
  background: $bodyBackgroundColor;
}

 // body classes
.page--refer-a-friend-reward,
.page--content-page,
.page--landing-page,
.page--category-list,
.page--category-landingpage,
.page--category-overview,
.page-home,
.page--search-list {
  .breadcrumbs {
    &__item::after {
      background: $whiteColor;
    }

    &__link-name {
      color: $whiteColor;
    }

    @media (max-width: $screen-mobile-large) {
      &--level {
        &_4,
        &_5 {
          & > :nth-child(1) .breadcrumbs__link {
            &::before {
              color: $whiteColor;
            }
          }
        }
      }
    }
  }
}
