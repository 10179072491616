.price-info {
  $root: &;
  color: $priceColor;

  &--reduction {
    color: $grayColor;
  }

  a {
    color: $priceColor;
    text-decoration: underline;
  }

  &__current {
    white-space: nowrap;
    color: $priceColor;
    font-weight: $fontWeightSemiBold;
    font-family: $fontPrimaryFamily;
    font-size: 1.6em;
    line-height: 120%;

    .basket-cross-selling & {
      font-size: 1.21em;
    }

    #{$root}--reduction & {
      color: $priceDiscountColor !important;
    }
  }

  &__currency {
    font-size: .75em;
    font-weight: $fontWeightSemiBold;
    font-family: $fontPrimaryFamily;
  }

  &__old {
    font-size: 1em;
  }

  &__old-value {
    @include strikethrough(currentColor);
    white-space: nowrap;
    color: $priceColor;
    font-weight: $fontWeightSemiBold;
  }

  &__asterisk {
    font-size: .5em;
    color: $priceColor;
    font-weight: $fontWeightRegular;
    position: relative;
    top: -.6em;
    left: -.1em;
  }

  &__unit {
    font-family: $fontPrimaryFamily;

    .basket-cross-selling & {
      font-size: 12px;
    }
  }

  &__unit-text,
  &__unit-price,
  &__unit-info {
    white-space: nowrap;
  }

  &__unit-price {
    display: inline-flex;
  }

  &__tax {
    font-size: 1em;
  }

  &--price-line-through {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-items: flex-end;

    .price-info__current {
      color: $priceColor !important;
      margin: 0 8px 0 0;
    }

    .price-info__unit {
      margin: 0 0 7px;
    }

    .price-info__value {
      position: relative;
      float: left;
      margin: 0 8px 0 0;
    }
  }
}
