.flag {
  margin-bottom: 8px;
  text-align: right;
  image-rendering: crisp-edges;

  &--stoerer {
    color: $flagTextColor;
    text-align: center;
    font-weight: $fontWeightRegular;
    margin-bottom: 5px;
    overflow: hidden;
    position: relative;
    background: $flagStoererColor;
    width: auto;
    height: auto;
    line-height: 1.3;
    padding: 1px 8px 0;
    display: inline-block;
    clear: both;
    float: right;
    font-size: $fontSizeSmall;
  }

  &--boxSetUrl,
  &--boxSetLayer {
    position: absolute;
    bottom: 0;
    background: $flagBoxSetUrlColor;
    border-radius: 5px;
    width: 66px;
    height: 42px;
    margin: auto;
    cursor: pointer;

    .image-element {
      display: flex;
      justify-content: center;
    }

    & img {
      height: 36px;
      position: relative;
      top: 3px;
    }
  }

  &--boxSetLayer {
    width: 100px;
    padding: 5px;
    height: auto;
    cursor: auto;
    margin: 0 0 10px;

    @media (min-width: $screen-tablet-landscape) {
      width: 180px;
      height: 42px;
    }

    & img {
      height: 31px;
      position: relative;
      top: 1px;
    }

    .flag__boxSetLayer { // stylelint-disable-line
      &-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        text-align: center;

        @media (min-width: $screen-tablet-landscape) {
          flex-wrap: nowrap;
          text-align: right;
        }
      }

      .image-element {
        width: 100%;

        @media (min-width: $screen-tablet-landscape) {
          width: auto;
        }
      }

      &-text {
        font-size: 12px;
        line-height: 1.2;
        padding: 0 0 4px;
        text-decoration: none;

        @media (min-width: $screen-tablet-landscape) {
          font-size: 11px;
          padding: 0 6px 0 0;
        }
      }

      .info-popup {
        @media (max-width: $screen-mobile-landscape-max) {
          width: 90% !important;
          top: 40%;
          left: 5%;
          bottom: auto;
          margin: 40px 0 0;
        }

        @media (min-width: $screen-tablet-portrait) {
          top: 45px;
          right: -6px;
          bottom: auto;
          left: auto;
        }

        &__info-container {
          display: flex;
        }

        &__info {
          display: none;

          @media (min-width: $screen-tablet-landscape) {
            order: 2;
            margin: 8px 0 8px 6px;
            display: block;
          }
        }

        &__label {
          text-decoration: none;
        }
      }
    }
  }

  &--free,
  &--new,
  &--newVintage {
    line-height: 1.1;
    text-align: center;
    width: 62px;
    border-radius: 0;
    padding: 5px 8px;
    font-size: 10px;
    background: $blackBlueColor;
    color: $whiteColor;
    letter-spacing: -.06em;

    strong {
      font-size: 14px;
    }
  }

  &--new {
    width: 45px;
    font-size: 14px;
    padding: 3px 8px 2px;
    font-weight: $fontWeightSemiBold;
    line-height: 1;
  }

  &--free {
    font-size: 16px;
    font-weight: $fontWeightSemiBold;
    line-height: 1;
  }

  &--takeAndPayBenefitText {
    background: $redColor;
    padding: 3px 5px;
    color: $whiteColor;
    text-align: left;
    font-size: 12px;
    font-weight: $fontWeightThin;
    margin: 4px 0;
    line-height: 1.25;
  }

  &--award {
    width: 98px;
  }
}
