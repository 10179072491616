.icon {
  @include iconFont('', before) {
    font-size: 40px;
    color: $textBaseColor;

    @media (min-width: $screen-desktop-sm) {
      font-size: 42px;
    }
  }
  position: relative;
  text-decoration: none;
  text-align: center;

  &:hover,
  &:visited {
    text-decoration: none;
  }

  @each $name, $glyph in $icons {
    &--#{$name}::before {
      content: $glyph;
    }
  }
}
