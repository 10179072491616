@import "../../../bps/components/processTeaser/processTeaser.scss";

.grid__element--bg-color3,
.grid__element--bg-color4,
.grid__element--bg-color5,
.page--content-page .row:not(.grid__element--bg-color1),
.page--landing-page .row:not(.grid__element--bg-color1) {
  .process-teaser:not(.process-teaser--gray) {
    color: $whiteColor;

    .process-teaser__headline.headline {
      color: $whiteColor;
    }

    .process-teaser__block {
      color: $whiteColor;

      a {
        color: $whiteColor;
      }
    }

    .process-teaser__icon {
      &::before {
        color: $whiteColor;
      }

      &--circle {
        color: $whiteColor;
      }
    }

    .process-teaser__divider {
      &::before {
        color: $whiteColor;
      }
    }
  }

  .process-teaser--gray {
    .process-teaser__icon::before {
      color: $textBaseColor;
    }
  }
}

.process-teaser {
  .button {
    color: $whiteColor !important;
    text-decoration: none !important;
  }
}
