@import '../../../bps/components/goTop/goTop.scss';

.go-top {
  &--sticky {
    bottom: 15px;
    right: 19px;

    @media (min-width: 1364px) {
      right: calc(50% - 672px);
    }
  }

  &--with-bottom-space {
    @media (max-width: $screen-tablet-portrait - 1) {
      bottom: 70px;

      .product-navigation--is-sticky & {
        bottom: 90px;
      }
    }
  }
}
