@import '../../../bps/components/searchAutosuggest/searchAutosuggest.scss';

.search-autosuggest {
  .input__button::after {
    color: $brandPrimaryColor;
  }

  .input__wrapper {
    .input__field {
      border-bottom: 1px solid $whiteColor;
    }
  }

  &__suggestions {
    &-list {
      &--left {
        padding: 0;
        width: 100%;
        max-width: none;
      }
    }
  }

  &__suggestion {
    padding: 10px 0;
  }
}
