.footer-service {
  &__grid-box {
    background: var(--footer-background);
    padding: $footerServicePadding;
    height: 100%;
    color: $footerServiceTextColor;

    & .html-block {
      color: $footerServiceTextColor;
      font-size: inherit;
    }

    > ul {
      .html-block & {
        margin: $footerServiceHeadlinePadding;
      }
    }

    &--blank {
      background: transparent;
      color: $footerServiceBlankColor;
      padding: 0;
    }

    &--logo {
      padding: 45px 0 8px;

      .image-element {
        display: flex;
        justify-content: center;

        img {
          width: 160px;
          height: 70px;
        }
      }
    }
  }

  &__headline {
    text-transform: uppercase;
    text-align: center;
    margin: $footerServiceHeadlinePadding;
    font-size: $footerServiceHeadlineFontSize;
    font-weight: $fontWeightBold;
    letter-spacing: 1px;

    &:first-child {
      margin-top: $footerServiceHeadlineFirstTop;
    }
  }

  &__text {
    &--big {
      @include fontSize($fontSizeXXXMedium);
      font-weight: $fontWeightSemiBold;
      margin-bottom: 8px;
    }

    &--small {
      @include fontSize($fontSizeSmall);
    }
  }

  &__content {
    list-style: none;
    text-align: center;
    padding: 0 !important;
    line-height: $footerServiceLineHeight;
    margin: 0 0 8px;

    @include fontSize($footerServiceFontSize);

    &--nav-line {
      & > * {
        display: inline-block;
        text-transform: uppercase;
        margin: 2px 5px;
        font-weight: $fontWeightSemiBold;
      }
    }

    & + & {
      margin-top: 24px;
    }

    &--checkout {
      display: flex;
      justify-content: center;
      margin: $footerServiceCheckoutMargin;

      li {
        display: inline-block;
        min-width: 90px;

        a:not(.no-link),
        a:not(.no-link):visited {
          color: $footerServiceLinkColor;
          text-decoration: none;
          padding: $footerServiceCheckoutPadding;
        }

        a:not(.no-link):hover {
          color: $footerServiceLinkColor;
        }
      }
    }
  }

  & &__content {
    a:not(.no-link),
    a:not(.no-link):visited {
      @include fontSize($footerServiceFontSize);
      color: $footerServiceLinkColor;
      font-weight: $fontWeightRegular;
      line-height: $footerServiceLinkLineHeight;
      text-decoration: none;
    }

    a:not(.no-link):hover {
      color: $footerServiceLinkColor !important;
      text-decoration: underline;
    }

    a:not(.no-link).footer-service__read-more {
      text-decoration: underline;
      margin: $footerServiceReadMoreMargin;
      display: inline-block;
    }
  }

  &__sprites {
    padding: $footerServiceSpritePadding;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    > i,
    > a {
      margin: $footerServiceIconMargin;
    }

    i,
    a {
      display: flex;
      text-decoration: none !important;
    }

    .icon {
      &::before {
        color: $footerServiceLinkColor;
        font-size: $footerServiceIconFontSize;
      }

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  &__icon-big {
    width: 67px;
    height: 67px;

    @media (min-width: $screen-tablet-sm) {
      width: 50px;
      height: 50px;
    }
  }
}
