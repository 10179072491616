.tabs-component {
  width: 100%;

  &-tab {
    color: $grayMediumColor;
    font-size: $fontSizeMedium;
    font-weight: $fontWeightSemiBold;
    margin: 0 20px 10px 0;
    list-style: none;
    position: relative;

    @media (min-width: $screen-tablet-landscape) {
      margin: 0 60px 0 0;
    }

    &:hover {
      color: $brandSecondaryColor;
    }

    &.is-active {
      color: $brandSecondaryColor;
      transform: translateY(0);

      &::after {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        width: 100%;
        height: 4px;
        margin: auto;
        border-radius: 5px;
        bottom: -1px;
        background-color: $brandHighlightColor;
      }
    }

    &.is-disabled * {
      color: $buttonDisabledTextColor;
      cursor: not-allowed !important;
    }
  }

  &-tabs {
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 100%;

    @media (min-width: $screen-tablet-portrait) {
      width: 60%;
    }

    @media (min-width: $screen-tablet-landscape) {
      margin: 0 0 10px;
      width: 75%;
    }
  }

  &-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: 5px 0;
    text-decoration: none;
  }

  &-panels {
    padding: 5px 0 0;
  }

  .cinema-teaser {
    position: relative;

    &__headline {
      min-height: 0;
      padding: 0;
      margin: 5px 0 0;
      display: flex;
      justify-content: center;
      flex-direction: column;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 47px;
        margin: 0;
      }
    }

    &__items-container {
      padding: 20px 0 0;
    }

    &__link:first-child {
      @media (min-width: $screen-tablet-portrait) {
        position: absolute;
        right: 0;
        top: -40px;
      }
    }
  }
}

.grid__element--bg-color1,
.grid__element--bg-color2 {
  .tabs-component {
    &-tab {
      color: $grayMediumColor;

      &:hover {
        color: $brandSecondaryColor;
      }

      &.is-active {
        color: $brandSecondaryColor;
      }
    }
  }
}
