@import '../../../bps/components/serviceTeaser/serviceTeaser.scss';

.service-teaser {
  color: $whiteColor;

  &__button {
    .button {
      background: transparent;
      border-color: $whiteColor;

      &:hover {
        text-decoration: none;
        border: 1px solid $buttonPrimaryBackgroundColor;
        color: $buttonPrimaryTextColor;
        background: $buttonPrimaryBackgroundColor;
      }
    }
  }

  &__headline,
  &__subline,
  &::before {
    color: $whiteColor;
  }

  &__headline {
    font-weight: $fontWeightBold;
    margin: 20px 0 3px;
  }

  &.icon--star {
    &::before {
      content: map-get($icons, diamond)
    }
  }
}

.grid__element--bg-color1,
.grid__element--bg-color2 {
  .service-teaser {
    &::before {
      color: $grayDarkColor;
    }

    &__headline {
      color: $grayDarkColor;
    }

    &__subline {
      color: $grayDarkColor;
    }
  }
}
