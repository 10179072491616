@import '../../../bps/components/addToCartLayer/addToCartLayer.scss';

.add-to-cart-layer {
  $root: &;

  &__cart-btn {
    background: $buttonPrimaryBackgroundColor;
    border-color: $buttonPrimaryBorderColor;

    &--hover,
    &:hover {
      background: $buttonPrimaryHoverBackgroundColor;
      border-color: $buttonPrimaryHoverBackgroundColor;
    }
  }
}
