@import '../../../bps/components/navigation/navigation.scss';

.navigation {
  @media (min-width: $screen-desktop-sm) {
    &__list {
      width: 100%;
    }

    &__element {
      padding: 5px 0 0;
    }

    &__link-name {
      padding: 3px 5px;
    }
  }
}
