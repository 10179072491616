@import '../../../bps/components/productBox/productBox.scss';

.product-box {
  $root: &;

  &__body {
    position: relative;
  }

  &__tags {
    margin: 0;

    .tag {
      @include tagOnProduct();
      margin: 0 0 6px;
      border-radius: 0;
      font-size: 12px;
      padding: 2px 8px;

      &.tag--cercle {
        margin-left: -80px;
      }
    }

    .tags__list {
      float: none;
      width: 100%;
      min-height: 24px;
    }
  }

  &__tags-wrapper {
    right: 0;
    text-align: right;
    z-index: 50;
    height: auto;
    min-width: auto;

    #{$root}--type-vertical & {
      top: 10px;
      left: auto;
    }

    #{$root}--type-horizontal & {
      top: 16px;
      left: auto;
      position: absolute;
    }

    &--hidden {
      display: none;
    }
  }

  &__vintage,
  &__short-description {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 2px;
    display: block;
    text-transform: uppercase;
  }

  &__short-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__name {
    font-size: $fontSizeMedium;
    margin: 0;
    max-height: 40px;
    padding-right: 24px;
    font-weight: $fontWeightBold;
  }

  &__image {
    justify-content: flex-end;
  }

  &__image-container {
    position: static;
  }

  &__button-wishlist {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__segment-header {
    min-height: 108px;
    position: relative;
    border: 0;
  }

  &__segment-image {
    #{$root}--type-vertical & {
      padding-left: 14px;
    }

    &.lazy-load {
      #{$root}--type-vertical & {
        min-height: 272px;

        @media (min-width: $screen-tablet-portrait) {
          min-height: 270px;
        }
      }
    }
  }

  &__conversion {
    z-index: 2;

    &:last-child {
      padding-bottom: 18px;
    }
  }

  &__bottle-amount {
    display: none;
  }

  &__status {
    line-height: 1.1;
    color: $whiteColor;
    background: $grayColor;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: $fontWeightSemiBold;
    text-transform: uppercase;
    padding: 15px 12px 14px;
    white-space: normal;
    z-index: 3;

    &--basket {
      &::before {
        margin: 0 6px 0 0;
        background: $whiteColor;
        color: $cyanColor;
        border-radius: 50%;
        padding: 3px;
      }
      display: flex;
      align-items: center;
      background: $cyanColor;
      padding: 8px 12px;
      letter-spacing: 0;
      font-weight: $fontWeightRegular;
      text-transform: none;
    }

    &--successor {
      background: $grayBlueColor;

      &::after {
        content: none;
      }
    }

    &--on-request {
      background: $grayBlueColor;
    }
  }

  .price-info {
    font-size: $fontSizeXSmall;

    &__current {
      font-size: $fontSizeXXLarge;
    }

    &__unit {
      color: $grayDarkColor;
    }
  }

  &__product-flags {
    top: 16px !important;
  }

  &__price-wrapper {
    width: 100%;

    #{$root}__price {
      .price-info__current {
        font-size: 24px;
        font-weight: 800;
      }
    }
  }

  &__buttons {
    min-height: 42px;
  }

  &__button-to-cart {
    width: 100%;
    min-width: 80px;
    max-width: 80px;

    @media (min-width: $screen-tablet-portrait) {
      min-width: 50px;
      max-width: 50px;
    }

    @media (min-width: $screen-desktop) {
      min-width: 80px;
      max-width: 80px;
    }
  }

  &__placeholder {
    .product-box__image {
      min-width: 160px;
    }

    .product-box__producer {
      display: none;
    }

    .product-box__vintage {
      font-weight: $fontWeightRegular;
    }
  }

  .counter {
    border-radius: 0;
  }
}
