.active-filters {
  $root: &;
  position: relative;

  @media (min-width: $screen-tablet-portrait) {
    padding-right: 180px;
  }

  &__filter,
  &__remove-all {
    display: inline-block;
    margin: 3px 6px 3px 0;
    position: relative;
    cursor: pointer;
  }

  &__remove-all {
    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
      position: absolute;
      right: 0;
      top: 3px;
    }
  }

  &__filter-text {
    font-size: 14px;
    line-height: 1.4;
    color: $grayDarkColor;
    font-weight: bold;
  }

  &__placeholder {
    @include componentPreloader();
    min-height: 92px;
  }
}
