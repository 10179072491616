.product-teaser-wide {
  $root: &;
  width: 100%;
  height: 100%;
  position: relative;
  padding: $imageTextTeaserBorderPadding;

  @media (min-width: $screen-tablet-portrait) {
    background: $whiteColor;
    min-height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  @media (min-width: $screen-tablet-landscape) {
    min-height: 400px;
  }

  .content-page__content &,
  .products-list-page:not(.products-list-page--no-sidebar) & {
    @media (min-width: $screen-tablet-landscape) {
      min-height: 280px;
    }

    @media (min-width: $screen-desktop) {
      min-height: 320px;
    }

    #{$root}__img {
      @media (min-width: $screen-tablet-portrait) {
        min-height: 330px;
        width: auto;
      }
    }

    #{$root}__box {
      @media (min-width: $screen-tablet-portrait) {
        max-width: 360px;
        width: 50%;
      }
    }
  }

  &__img-wrapper {
    overflow: hidden;

    @media (min-width: $screen-tablet-portrait) {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .image-element {
      width: 100%;
      display: flex;

      @media (min-width: $screen-tablet-portrait) {
        height: 100%;
      }

      .is_safari & {
        display: block;
      }
    }
  }

  &__img {
    min-height: auto;
    max-height: 400px;
    width: 140%;

    @media (min-width: $screen-tablet-portrait) {
      min-height: auto;
      width: 100%;
      max-height: 100%;
      height: auto;
      align-self: flex-end;
    }

    @media (min-width: $screen-tablet-landscape) {
      min-height: 180px;
      width: 100%;
    }

    @media (min-width: $screen-desktop) {
      min-height: 400px;
      width: auto;
      max-width: none;
    }

    #{$root}--product-image & {
      width: auto;
      margin: auto;

      @media (min-width: $screen-desktop) {
        margin: auto 0;
      }
    }
  }

  &__img-product-wrapper {
    width: 100%;
    display: block;
    position: relative;
  }

  &__box {
    position: relative;
    background: rgb(255 255 255 / 1);
    padding: 16px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;

    @media (min-width: $screen-tablet-portrait) {
      background: rgb(255 255 255 / .8);
      margin: 16px;
      min-width: 280px;
      max-width: 340px;
      width: 36%;
    }

    @media (min-width: $screen-tablet-landscape) {
      width: 30%;
    }
  }

  &__box-top {
    flex-grow: 1;
    padding: 0 0 20px;
  }

  &__link {
    text-decoration: none;
  }

  &__ratings {
    font-size: 15px;
    padding-top: 0;
  }

  &__header {
    font-size: $fontSizeXXMedium;
    color: $textBaseColor;
    margin-bottom: 16px;
    line-height: 1.1;
    overflow: hidden;
    position: relative;

    @media (min-width: $screen-tablet-landscape) {
      font-size: $fontSizeXLarge;
    }
  }

  &__subline {
    font-size: $fontSizeSmall;
    color: $textBaseColor;
    padding: 0;
    display: inline-block;
    line-height: 1.12;
    max-height: 12em;
    overflow: hidden;
    position: relative;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0 0 1em;
    }

    ul {
      padding: 0 0 0 1em;
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      margin: 0 0 1em;
    }
  }

  &__specification-link {
    display: block;
    width: 100%;
    color: $productBoxLinkColor;
    font-size: $productBoxLinkFontSize;
    line-height: 1;
    text-decoration: underline;
    text-decoration-color: lighten($productBoxLinkColor, 20%);
    margin-top: 8px;
  }

  &__price {
    font-size: $fontSizeXXSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
    }

    .price-info {
      margin-bottom: 8px;

      &__current {
        font-size: 24px;
        display: inline-block;
        color: $grayDarkColor;

        @media (min-width: $screen-tablet-portrait) {
          font-size: $fontSizeXXLarge;
        }

        @media (min-width: $screen-laptop) {
          font-size: 34px;
        }
      }

      &__unit {
        color: $grayColor;
        display: inline-block;
        width: 100%;
        padding: $productTeaserPriceUnitPadding;
      }

      &__asterisk {
        font-size: .5em;
        top: -.4em;
        left: -.1em;
      }
    }
  }

  &__cart-btn {
    flex-grow: 1;
    width: auto;
  }

  &__status {
    flex-grow: 1;
    width: auto;
    max-width: 200px;
  }

  .product-box__status--basket-up {
    display: block;
    margin: -8px -8px 8px;
  }

  .availability-info {
    width: 100%;
  }

  .availability-info--statement {
    text-align: left;
    padding: 0 0 10px;
  }

  &__conversion {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__qty-wrapper {
    margin-right: 8px;
  }

  &--too-high {
    #{$root}__ratings {
      display: none;
    }

    #{$root}__box {
      @media (min-width: $screen-tablet-portrait) {
        max-width: 380px !important;
      }
    }
  }

  .product-qty-and-cart-btn {
    flex-grow: 1;
  }

  .lazy-load {
    min-height: 30px;
  }
}
