.footer {
  width: 100%;
  min-height: 90px;
  text-align: center;
  background: $bodyBackgroundColor;

  .checkout-layout & {
    margin: 40px 0 0;
  }

  &__row-wrapper {
    display: block;
    padding: 20px 0;

    &:empty {
      border: 0;
    }

    @media (min-width: $screen-laptop) {
      padding: 40px 0;
    }
  }

  &__row {
    @include container();
    display: block;
  }

  .grid {
    margin-left: -4px;
    margin-right: -4px;
    width: calc(100% + 8px);

    .row {
      align-items: stretch !important;
    }

    .col-lg-2 {
      @media (min-width: $screen-mobile-landscape) {
        width: 50%;
        margin: 0 auto;
      }

      @media (min-width: $screen-tablet-sm) {
        width: 33.333%;
        margin: 0;
      }

      @media (min-width: $screen-laptop) {
        width: 20%;
      }
    }

    .col-lg-3 {
      width: 100%;

      @media (min-width: $screen-tablet-sm) {
        width: 33.333%;
        margin: 0;
      }

      @media (min-width: $screen-laptop) {
        width: 20%;
      }
    }

    .col-lg-5 {
      width: 100%;

      @media (min-width: $screen-laptop) {
        width: 40%;
      }
    }
  }

  .grid--raster > .row > div:not(:empty) {
    .default-layout & {
      padding: 4px !important;
    }
  }

  .html-teaser {
    height: 100%;
  }

  .html-block {
    .trusted-shop-badge__more-link a,
    a,
    p {
      color: $footerServiceTextColor;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-service__text {
    margin-top: 13px;
    margin-bottom: 8px;
  }

  &-service__content--nav-line {
    margin: 13px 0 8px !important;
  }

  &__trusted-shop-logo img {
    width: 90px;
    height: 90px;
    margin-bottom: -10px;
  }

  &__trusted-shops {
    margin: 46px 0 0;

    .trusted-shop-badge__more-link {
      margin: 6px;
    }
  }

  &__social {
    margin: 35px 0;
  }

  .icons {
    .icon {
      &::before {
        color: $footerServiceLinkColor;
        font-size: $footerServiceIconFontSize;
      }
    }

    a {
      margin: 2px 5px;
      text-decoration: none !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .newsletter-teaser {
    background: var(--footer-background);

    &__headline {
      font-size: 20px;
      font-weight: $fontWeightSemiBold;

      @media (min-width: $screen-tablet-portrait) {
        margin: 15px 0 0;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin: 26px 0 0;
        text-align: right;
      }
    }

    &__flag {
      display: none;
    }

    &__form {
      margin-top: 10px;
      text-align: left;
    }

    &__step-1 {
      @media (min-width: $screen-tablet-portrait) {
        padding: 0 20px;
        display: flex;
        margin: 0;
        justify-content: center;
        flex-wrap: wrap;
      }

      @media (min-width: $screen-desktop) {
        justify-content: flex-start;
      }

      .newsletter-teaser__row {
        @media (min-width: $screen-tablet-portrait) {
          min-width: 50%;
          width: 50%;
        }
      }

      .newsletter-teaser__row--head {
        max-width: none;
        padding: 0;

        @media (min-width: $screen-tablet-portrait) {
          padding: 0 20px 0 0;
        }

        @media (min-width: $screen-desktop) {
          min-width: auto;
          width: auto;
        }
      }

      .newsletter-teaser__row--footer {
        max-width: 100%;
        width: 100%;
        padding: 0;
        margin: -6px 0 0;
      }
    }

    .newsletter-form .form__group:not(.form__group--row):last-child {
      margin: 0;
    }

    .newsletter-form__declaration {
      margin: -2px 0 2px;
      text-align: left;
      font-size: 12px !important;

      a {
        font-size: 12px !important;
      }
    }

    a:not(.no-link) {
      font-weight: inherit;
      text-decoration: underline;
      line-height: inherit;
    }

    &__success {
      background: $whiteColor;

      .newsletter-teaser__headline {
        @include fontSize($fontSizeXXXLarge);
        text-align: center;
      }

      .newsletter-form__declaration {
        text-align: center;
        display: block !important;
      }
    }
  }
}
