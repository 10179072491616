.product-teaser {
  $root: &;
  $marginOverlap: 50%;
  width: 100%;
  height: 100%;
  position: relative;
  border: $imageTextTeaserBorder;
  padding: $imageTextTeaserBorderPadding;
  min-height: 400px;

  &__action-wrapper {
    display: flex;
  }

  &__link {
    display: block;
    width: 100%;
    text-decoration: none;
    min-height: 80px;
  }

  &__img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    align-self: flex-start;

    & > img {
      width: 100%;
      display: inherit;
    }
  }

  &__col-wrapper {
    height: 100%;
  }

  &__img-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    &--product {
      width: 100%;
      background: $whiteColor;
      position: relative;

      img {
        width: auto;
        height: auto;
        max-height: 212px;
        margin: 0 auto $marginOverlap;
        max-width: 100%;
      }
    }

    .image-element {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &__img-product-wrapper {
    width: 100%;
    display: block;
    position: relative;
  }

  &__img-product {
    width: auto;
    height: 200px;
    position: absolute !important;
    bottom: 25%;
    right: 30%;
    max-height: 70%;

    & > img {
      height: 100%;
      position: relative;
    }

    .product-teaser--offer & {
      height: 60%;
      bottom: 27%;
      max-width: 70px;
      text-align: center;

      @media (min-width: $screen-laptop) {
        height: 62%;
        bottom: 28%;
      }

      @media (min-width: $screen-desktop) {
        bottom: 27%;
      }
    }
  }

  &__box {
    background: $imageTextTeaserBoxBackground;
    padding: 16px;
    position: $imageTextTeaserBoxPosition;
    z-index: 1;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    min-height: $imageTextTeaserMinHeight;
  }

  &__header {
    @include fontSize($imageTextTeaserHeadlineSize);
    color: $imageTextTeaserHeadlineColor;
    margin-bottom: 16px;
    line-height: 115%;
  }

  &__subline {
    color: $textBaseColor;
    padding: 0 0 16px;
    display: inline-block;
    flex-grow: 2;
    line-height: 1.2;
  }

  &__specification-link {
    display: block;
    width: 100%;
    color: $productBoxLinkColor;
    font-size: $productBoxLinkFontSize;
    line-height: 1;
    text-decoration: underline;
    text-decoration-color: lighten($productBoxLinkColor, 20%);
    margin-top: 8px;
    text-align: right;
  }

  &__flags {
    position: absolute;
    right: 16px;

    &--flag1 {
      $sizeM: 48px;
      $sizeD: 64px;
      top: -$sizeM * .5;

      @media (min-width: $screen-tablet-portrait) {
        top: -$sizeD * .5;
      }

      .flag {
        width: $sizeM;
        height: $sizeM;
        font-size: 15px;

        @media (min-width: $screen-tablet-portrait) {
          width: $sizeD;
          height: $sizeD;
          font-size: 20px;
        }
      }
    }

    &--flag2 {
      top: 16px;
    }
  }

  &__conversion {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-grow: 1;
  }

  &__price {
    font-size: $fontSizeXXSmall;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
    }

    .price-info {
      &__current {
        font-size: 24px;
        display: inline-block;
        color: $grayDarkColor;

        @media (min-width: $screen-tablet-portrait) {
          font-size: $fontSizeXXLarge;
        }

        @media (min-width: $screen-laptop) {
          font-size: $fontSizeXXXLarge;
        }
      }

      &__unit {
        color: $grayColor;
        display: inline-block;
        width: 100%;
        padding: $productTeaserPriceUnitPadding;
      }

      &__asterisk {
        font-size: .5em;
        top: -.4em;
        left: -.1em;
      }
    }
  }

  .product-box__status--basket-up {
    display: inline-flex;
    margin: 0 0 5px;
  }

  .availability-info {
    width: 100%;
  }

  &__product-flag {
    position: absolute;
    top: 12px;
    left: 0;
    color: $whiteColor;
    font-size: 10px;
    line-height: 1.3;

    @media (min-width: $screen-tablet-portrait) {
      top: 16px;
      font-size: 14px;
    }

    &--day-offer,
    &--day-offer-xmas {
      background: $dayOfferColor;
      padding: 8px 8px 8px 16px;
      max-width: 100px;
      position: relative;
      font-size: 14px;

      p {
        padding: 0;
        margin: 0;
        z-index: 1;
        position: relative;
      }

      &::before,
      &::after {
        left: 50%;
        right: 0;
        position: absolute;
        z-index: 0;
        background: inherit;
        content: "";
        display: block;
      }

      &::before {
        top: 0;
        bottom: 50%;
        transform: skewX(28deg);
        transform-origin: top;
      }

      &::after {
        top: 50%;
        bottom: 0;
        transform: skewX(-28deg);
        transform-origin: bottom;
      }
    }

    &--day-offer-xmas {
      background: $redColor;
      max-width: 130px;
    }
  }

  &__product-button {
    width: 100%;
    padding: 8px 0;

    .button {
      width: 100%;
    }
  }

  &__button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__qty-wrapper {
    margin-right: 8px;
  }

  .lazy-load {
    min-height: 30px;
  }

  .teasergroup_teaser--asymmetric & { // stylelint-disable-line
    height: 100%;
    display: flex;
    flex-direction: column;

    #{$root}__box {
      flex: 1;
    }
  }
}
