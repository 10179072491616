.header {
  $transitionTime: .25s;
  $transitionDelay: .25s;
  $transition: $transitionTime cubic-bezier(.25, .46, .45, .94);
  position: relative;
  z-index: $indexHeader;

  &--search-active {
    z-index: 610;
  }

  &__top-bar {
    position: relative;
    z-index: 0;

    .header__sticky--active & {
      display: none;
    }
  }

  &__meta-menu {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      @include container();
      display: block;
    }
  }

  &__sticky {
    box-shadow: 0 0 5px 1px rgb(0 0 0 / .5);
    background: var(--header-background);

    &--enabled,
    &--active {
      will-change: position;
      position: relative;
      width: 100%;
    }

    &--active {
      position: fixed;
      top: 0;
      left: 0;
      animation: header-sticky .2s forwards;
    }
  }

  &__main {
    @include container();
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      min-height: 75px;
      padding-left: 3px;
    }

    @media (min-width: $screen-desktop-sm) {
      min-height: 90px;
      padding-left: 20px;

      .header__sticky--active & {
        min-height: 75px;
        padding-left: 3px;
      }
    }
  }

  &__logo-wrapper {
    display: block;
    height: auto;
    text-align: center;
  }

  &__logo-link {
    display: inline-block;
    z-index: 600;
    width: 100px;
    height: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    padding: 10px 0;

    @media (min-width: $screen-tablet-portrait) {
      width: 160px;
      top: 4px;
    }

    @media (min-width: $screen-desktop-sm) {
      width: 280px;
      top: 8px;
      padding: 0;
    }

    .header__main--type_checkout & {
      top: 3px;
    }

    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        width: 160px;
        top: 15px;
      }
    }
  }

  &__logo {
    width: 100%;
    height: auto;
    display: block;
  }

  &__search-trigger,
  &__burger-menu-trigger {
    order: 1;
    font-size: 23px;
    display: block;
    padding: 10px 18px;
    text-align: center;
    position: relative;
  }

  &__burger-menu-trigger {
    padding: 22.5px 19px;

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }
  }

  &__search-trigger {
    padding: 13px 18px 8px;
    color: $whiteColor;
  }

  &__burger-menu {
    display: block;
    background: $headerIconColor;
    width: $iconBurgerWidth;
    height: $iconBurgerHeight;
    border-radius: $iconBurgerHeight;
    position: relative;
    transition-duration: $iconBurgerDuration;
    transition-delay: $iconBurgerDelay;
    vertical-align: middle;

    @media (min-width: $screen-tablet-portrait) {
      order: 4;
    }

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }

    &::before,
    &::after {
      position: absolute;
      content: '';
      background: $headerIconColor;
      width: $iconBurgerWidth;
      height: $iconBurgerHeight;
      border-radius: $iconBurgerHeight;
      left: 0;
    }

    &::before {
      top: -6px;
      transition: $iconBurgerPseudoTransition;
    }

    &::after {
      top: 6px;
      transition: $iconBurgerPseudoTransition;
    }

    &--open,
    .burger-menu--open & {
      transition-duration: .1s;
      transition-delay: $iconBurgerDelay;
      background: transparent;

      &::before {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(-45deg);
      }

      &::after {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(45deg);
      }
    }
  }

  &__search-icon {
    @include iconFont(search);

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }
  }

  &__user-shortcuts {
    order: 3;
    position: relative;
    width: auto;
    margin: 0 10px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-grow: 1;

    @media (min-width: $screen-tablet-portrait) {
      margin: 0;
    }

    @media (min-width: $screen-desktop-sm) {
      margin: 0 14px 0 0;
      position: relative;
      bottom: 45px;
      flex-grow: 0;

      .header__sticky--active & {
        bottom: 0;
        flex-grow: 1;
      }
    }
  }

  &__user-shortcut {
    &--wishlist {
      display: none !important;

      @media (min-width: $screen-tablet-portrait) {
        display: block !important;
      }
    }
  }

  &__navigation {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      order: 4;
      display: block;
      width: 100%;
      margin: 54px 0 13px;
      transition: opacity $transitionTime;
      transition-delay: $transitionDelay;
    }

    .header__sticky--active & {
      display: none;
    }

    .navigation__list > .navigation__element {
      &.highlight .navigation__link-name {
        border: 1px solid $whiteColor;
        border-radius: 0;
      }

      .navigation__link-name {
        color: $whiteColor;
        text-transform: uppercase;
        font-weight: $fontWeightThin;

        &:hover {
          color: $whiteColor !important;
        }
      }
    }
  }

  .navigation__element {
    &:hover {
      border-bottom: 2px solid $whiteColor;
    }
  }

  &__search {
    margin: 10px auto;
    width: 95%;
    order: 5;
    display: none;
    position: relative;

    @media (min-width: $screen-tablet-portrait) {
      width: 63%;
      margin: 0 auto 10px;
    }

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 22.9%;
      position: absolute;
      right: 20px;
      bottom: 81px;
      margin: 0;
      transition: width $transition, right $transition;
      transition-delay: $transitionDelay;
    }

    .header__sticky--active & {
      display: none;
    }

    .header--search-active & {
      display: block;
    }

    .header--search-active .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        bottom: 0;
        right: 0;
        position: relative;
        width: 70%;
        margin: 0 auto 10px;
      }
    }

    .search-autosuggest__input {
      .input__wrapper {
        border-radius: 0;
        background: $whiteColor;

        .input__field {
          font-size: $fontSizeSmall;
        }
      }
    }

    .search-autosuggest__close {
      opacity: 0;
      transition: opacity .1s linear;
      transition-delay: $transitionTime + $transitionDelay;

      .header__sticky--active & {
        @media (min-width: $screen-desktop-sm) {
          display: none;
        }
      }
    }

    &.search-autosuggest--focus {
      @media (min-width: $screen-desktop-sm) {
        width: 35%;
        right: 20px;
      }

      .search-autosuggest__close {
        @media (min-width: $screen-desktop-sm) {
          display: none;
        }
      }
    }
  }

  &__product-flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background: $backgroundWhiteColor;
    border-radius: 5px;
    top: 100%;
    margin-top: 10px;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    z-index: $indexBasketFlyout;
  }

  &__checkout-process-breadcrumbs-wrapper {
    position: relative;
  }

  &__checkout-process-breadcrumbs {
    order: 6;
  }
}

@keyframes header-sticky {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}
