@import "../../../bps/components/pageIntro/pageIntro.scss";

.page-intro {
  $root: &;

  &__button {
    @include fontSize($fontSizeSmall);
    text-decoration: underline;
    font-weight: $fontWeightSemiBold;
    letter-spacing: 1px;
    margin-top: 16px;

    &::after {
      display: none;
    }
  }

  &__text {
    @include fontSize($baseFontSize);
    color: $whiteColor;

    a {
      color: $whiteColor;
    }

    #{$root}--no-image & {
      width: 100%;
    }

    #{$root}--mood & {
      @media (min-width: $screen-tablet-portrait) {
        width: 65%;
      }

      @media (min-width: $screen-desktop) {
        width: 60%;
      }
    }
  }

  .mood-teaser {
    &--no-mobile .mood-teaser__picture {
      @media (max-width: 545px) {
        visibility: hidden;
      }
    }

    &--no-desktop .mood-teaser__picture {
      @media (min-width: 546px) {
        visibility: hidden;
      }
    }
  }
}
