@import '../../../bps/components/additionalContent/additionalContent.scss';

.additional-content {
  $root: &;
  margin: 0 auto !important;
  padding: 20px 10px !important;

  @media (min-width: $screen-mobile-large) {
    padding: 20px 15px !important;
  }

  @media (min-width: $screen-tablet-portrait) {
    padding: 20px 20px 30px !important;
  }

  #{$root}__wrapper {
    color: $footerSeoColor;
    width: 100%;
    padding: 0;

    * {
      color: $footerSeoColor;
    }
  }

  .footer-seo__text-wrapper {
    padding: 0;

    @media (min-width: $screen-tablet-portrait) {
      max-width: none;
    }
  }
}
