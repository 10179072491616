@import '../../../bps/components/tag/tag.scss';

@mixin tagOnProduct() {
  $backgroundColor: #a7a19d;
  border-radius: 5px;
  background: $backgroundColor;
  font-size: $fontSizeSmall;
  line-height: 1.3;
  padding: 1px 8px 0;

  &:hover {
    background: $backgroundColor;
  }

  &.tag--cercle {
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }
}
