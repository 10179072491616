@import '../../dependencies.scss';
@import '../../../bps/components/productTeaserWide/productTeaserWide.scss';

.product-teaser-wide {
  &__cart-btn {
    flex-grow: 1;
    width: auto;
    text-transform: uppercase;
    font-weight: $fontWeightBold;
    font-size: $fontSizeSmall;

    &::after {
      transform: translate(4px, 4px);
    }
  }

  &__box {
    background: #fff;
  }

  .product-qty-and-cart-btn {
    flex-grow: 1;
    justify-content: flex-start;
  }

  .image-element {
    position: relative;
  }

  @media (min-width: $screen-tablet-portrait) {
    &__img {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      width: auto;
      height: 100%;
      max-width: none;
      max-height: none;
    }
  }

  .product-box__status--basket-up {
    margin: -4px 0 12px;
  }
}
