.header-meta-content {
  @media (min-width: $screen-tablet-portrait) {
    padding: 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__links {
      display: flex;
      justify-content: flex-start;
      min-height: 26px;

      .html-teaser a {
        color: $whiteColor !important;
        font-weight: $fontWeightRegular;
        text-decoration: none;
        font-size: $fontSizeSmall;
        display: inline-block;
        position: relative;
        padding: 0 10px;

        &:hover,
        &:visited {
          color: $whiteColor !important;
          text-decoration-color: $whiteColor !important;
        }

        &:hover {
          color: inherit;
          text-decoration: underline;
          text-decoration-color: $textHoverColor;
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
}
