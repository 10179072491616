$icons: (
  arrowDown: "\ea01",
  arrowDownBold: "\ea02",
  arrowLeft: "\ea03",
  arrowLineRight: "\ea04",
  arrowRight: "\ea05",
  arrowUp: "\ea06",
  arrowUpBold: "\ea07",
  benefitsBottle: "\ea08",
  benefitsChecklist: "\ea09",
  benefitsDhl: "\ea0a",
  benefitsGaranty: "\ea0b",
  benefitsPaket: "\ea0c",
  benefitsService: "\ea0d",
  benefitsThumb: "\ea0e",
  benefitsWineglas: "\ea0f",
  bestonlineshop: "\ea10",
  calendar: "\ea11",
  camera: "\ea12",
  cart: "\ea13",
  certificate: "\ea14",
  checkSmall: "\ea15",
  checked: "\ea16",
  checklist: "\ea17",
  close: "\ea18",
  cloud: "\ea19",
  comment: "\ea1a",
  confirmationArrow: "\ea1b",
  confirmationBox: "\ea1c",
  confirmationHome: "\ea1d",
  confirmationMail: "\ea1e",
  confirmationTracking: "\ea1f",
  confirmationVersand: "\ea20",
  cursorSelect: "\ea21",
  delete: "\ea22",
  delivery: "\ea23",
  diamond: "\ea24",
  documentCheck: "\ea25",
  edit: "\ea26",
  eurCircle: "\ea27",
  exitFullscreenIcon: "\ea28",
  expand: "\ea29",
  eye: "\ea2a",
  facebook: "\ea2b",
  facebookInverted: "\ea2c",
  filter: "\ea2d",
  fwAuswaehlen: "\ea2e",
  fwEinkaufen: "\ea2f",
  fwEmpfehlen: "\ea30",
  fwPraemieErhalten: "\ea31",
  garantie: "\ea32",
  giftcard: "\ea33",
  headset: "\ea34",
  heart: "\ea35",
  heartFilled: "\ea36",
  heartUnfilled: "\ea37",
  hochwertigessortiment: "\ea38",
  home: "\ea39",
  hundertpunkte: "\ea3a",
  infoPopup: "\ea3b",
  instagram: "\ea3c",
  letter: "\ea3d",
  linkLetter: "\ea3e",
  loading: "\ea3f",
  logoClaim: "\ea40",
  mail: "\ea41",
  mailOpen: "\ea42",
  packet: "\ea43",
  phone: "\ea44",
  plus: "\ea45",
  productAnbaugebiet: "\ea46",
  productBox: "\ea47",
  productLagerfaehigkeit: "\ea48",
  productRebsorten: "\ea49",
  productTrinktemperatur: "\ea4a",
  productrange: "\ea4b",
  redwine: "\ea4c",
  reload: "\ea4d",
  return: "\ea4e",
  rosewine: "\ea4f",
  schnelleLieferung: "\ea50",
  search: "\ea51",
  shipping: "\ea52",
  star: "\ea53",
  statusDelayed: "\ea54",
  statusDelivery: "\ea55",
  statusHome: "\ea56",
  statusPackage: "\ea57",
  statusReceived: "\ea58",
  talk: "\ea59",
  thumbDown: "\ea5a",
  thumbUp: "\ea5b",
  thumbUpFill: "\ea5c",
  time: "\ea5d",
  trash: "\ea5e",
  user: "\ea5f",
  userMailCheck: "\ea60",
  vcard: "\ea61",
  video: "\ea62",
  voucherFive: "\ea63",
  weinshopDesJahres: "\ea64",
  whatsapp: "\ea65",
  zoomIn: "\ea66",

);