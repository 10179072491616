.video-player {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  &__preview-img {
    width: 100%;
    position: relative;
    z-index: 1;

    .video-player--activated & {
      position: static;
    }
  }

  &__preview-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 2;

    &:hover::before {
      background: $brandPrimaryColor;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
    }

    &::before {
      border-radius: 4px;
      background: $grayMediumColor;
      width: 90px;
      height: 50px;
      transform: translate(-50%, -50%);
      transition: background-color .2s;
    }

    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 17px;
      border-color: transparent transparent transparent $whiteColor;
      transform: translate(-40%, -50%);
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
