@import '../../../bps/components/productBoxWide/productBoxWide.scss';

.product-box-wide {
  .price-info {
    font-size: $fontSizeXSmall;

    &__current {
      font-size: $fontSizeXXLarge;
    }

    &__unit {
      color: $grayDarkColor;
    }
  }

  &__segment-image {
    position: relative;
  }

  &__product-flags {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    left: auto;

    .flags {
      text-align: right;
    }
  }

  &__image-wrapper--bottle {
    padding: 10px;

    .product-box-wide__image img {
      max-height: 270px;
    }
  }

  &__long-description {
    margin-top: -10px;
  }
}
