.trusted-shop-review-button {
  background: $whiteColor;
  border-color: $grayBlueColor;
  color: $grayBlueColor;
  font-weight: $fontWeightBold;

  @include iconFont(star) {
    font-size: $fontSizeXXXMedium;
    transform: translateY(25%);
  }

  &:hover {
    background: $whiteColor;
    color: $grayBlueColor;
  }
}
