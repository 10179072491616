@import '../../../bps/components/tags/tags.scss';

.tags {
  &__tag {
    a {
      color: inherit;
      line-height: 1.4;
      margin: 0 0 2px;
      display: inline-block;

      .product-box & {
        font-size: 13px;
      }
    }
  }
}
