@import '../../../bps/components/htmlBlock/htmlBlock.scss';

.grid__element--bg-color3,
.grid__element--bg-color4,
.grid__element--bg-color5,
.product-detailed-page__bottom,
.page--content-page .row:not(.grid__element--bg-color1),
.page--landing-page .row:not(.grid__element--bg-color1) {
  .html-block {
    color: $whiteColor;

    .icon::before {
      color: $whiteColor;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: inherit;
    }

    p,
    ul,
    ol,
    li,
    b,
    strong,
    table,
    td {
      color: inherit;
    }

    a {
      color: inherit;

      &:visited,
      &:hover {
        color: inherit;
      }
    }

    table,
    blockquote {
      border-color: inherit;
    }
  }
}

.product-detailed-page__bottom .row.grid__element--bg-color1,
.product-detailed-page__bottom .row.grid__element--bg-color2 {
  .html-block {
    color: $htmlBlockTextColor;
  }
}

.html-block {
  &__block-white {
    margin: 20px 0;
    padding: 20px;
    background: $whiteColor !important;
    color: $htmlBlockTextColor !important;
  }

  &__block-border-white {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid $whiteColor;
  }

  h2 {
    font-size: 26px;
    font-weight: 300;
    text-transform: uppercase;
  }
}
