@import "../../../bps/components/productBoxSlider/productBoxSlider.scss";

.product-box-slider {
  .page-home & {
    .product-box-slider__item {
      border: 1px solid $grayColor;
    }
  }

  .page--category-list &,
  .page--content-page &,
  .page--landing-page &,
  .grid__element--bg-color3 &,
  .grid__element--bg-color4 &,
  .grid__element--bg-color5 & {
    .product-box-slider__item {
      border: 0 none;
    }
  }

  &__slider .product-box__product-flags {
    top: 10px;
    left: 0;
  }

  &__slider {
    .flags {
      margin: 0;
    }
  }
}

.page--category-list,
.page--content-page,
.page--landing-page {
  .grid__element--bg-color1 .product-box-slider .product-box-slider__item,
  .grid__element--bg-color2 .product-box-slider .product-box-slider__item {
    border: 1px solid $grayColor;
  }
}
