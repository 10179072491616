@import '../../../bps/components/contentPage/contentPage.scss';

.content-page {
  .page-intro {
    color: $whiteColor;
  }

  .carousel {
    .carousel__button {
      &--prev {
        @media (min-width: $screen-tablet-portrait) {
          left: -20px;
        }

        @media (min-width: $screen-desktop-xl) {
          left: -35px;
        }
      }

      &--next {
        @media (min-width: $screen-tablet-portrait) {
          right: -20px;
        }

        @media (min-width: $screen-desktop-xl) {
          right: -35px;
        }
      }
    }
  }
}
