.winemaker-price {
  color: $textDarkestColor;
  font-size: 12px;
  margin: 8px 0 4px;

  .product-box__conversion & {
    margin: 8px 0 0;
  }

  .product-teaser__price & {
    margin: 0 0 4px;
  }

  .sale-box__availability-info & {
    margin: 4px 0 0;
  }

  .info-popup__info {
    margin: -3px 1px 0 0;
  }

  .info-popup--box-right {
    @media (min-width: $screen-tablet-portrait) {
      top: 20px;
      right: -60px;
      left: auto;
      bottom: auto;
    }
  }

  .info-popup--salebox-right {
    @media (min-width: $screen-tablet-portrait) {
      bottom: 20px;
      left: 0;
      right: auto;
      top: auto;
    }
  }

  &--sale-box {
    justify-content: space-between !important;
  }
}
