.social-media {
  display: flex;
  padding: 15px 0;
  flex-direction: row;
  justify-content: flex-end;

  &__link {
    display: block;
    position: relative;
    width: auto;
    height: auto;
    font-size: 17px;
    font-weight: $socialMediaFontWeight;
    text-decoration: none;
    margin: 0 2px;
    color: $socialMediaLinkColor;

    &:hover,
    &:focus {
      color: $socialMediaLinkHoverColor;
    }

    &--pinterest {
      @include iconFont(pinterest);
    }

    &--facebook {
      @include iconFont(facebook);
    }

    &--twitter {
      @include iconFont(twitter);
    }

    &--mail {
      @include iconFont(mail);
    }

    &--instagram {
      @include iconFont(instagram);
    }

    &--whatsapp {
      @include iconFont(whatsapp);

      @media (min-width: $screen-desktop) {
        display: none !important;
      }
    }
  }
}
