@import '../../../bps/components/availabilityInfo/availabilityInfo.scss';

.availability-info {
  &__label {
    color: $textGrayColor !important;
  }

  &--label {
    font-weight: $fontWeightRegular;
    text-transform: none;
  }
}
