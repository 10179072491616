.html-block {
  $root: &;
  color: $htmlBlockTextColor;
  line-height: $htmlBlockTextLineHeight;

  @include fontSize($htmlBlockTextFontSize);

  &--with-spacing {
    width: 100%;
    max-width: $maxWidthOuter;
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px auto;

    @media (min-width: $screen-mobile-large) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding-left: 20px;
      padding-right: 20px;
      margin: 40px auto;
    }

    &.additional-content {
      @media (min-width: $screen-desktop-xl) {
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto 40px;
      }
    }
  }

  &--negativ-spacing-top {
    margin-top: -20px;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: -40px;
    }
  }

  &--negativ-spacing-bottom {
    margin-bottom: -20px;

    @media (min-width: $screen-tablet-portrait) {
      margin-bottom: -40px;
    }
  }

  &--overflow {
    overflow: auto;
  }

  &--spacing-top {
    padding-top: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
    }
  }

  &--spacing-bottom {
    padding-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-bottom: 40px;
    }
  }

  &--spacing-top-bottom {
    padding-top: 30px;
    padding-bottom: 30px;

    @media (min-width: $screen-tablet-portrait) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  h1 {
    @include fontSize($headlineH1FontSize);
    font-weight: $headlineH1FontWeight;
  }

  h2 {
    @include fontSize($headlineH2FontSize);
    font-weight: $headlineH2FontWeight;
  }

  h3 {
    @include fontSize($headlineH3FontSize);
    font-weight: $headlineH3FontWeight;
  }

  h1,
  h2,
  h3 {
    margin: 20px 0 10px;
    line-height: $htmlBlockTextLineHeight;

    &:first-child {
      margin-top: 0;
    }
  }

  h4,
  h5,
  h6 {
    @include fontSize($headlineH4FontSize);
    font-weight: $headlineH4FontWeight;
    margin: 20px 0 10px;
    line-height: $htmlBlockTextLineHeight;
  }

  p {
    color: $htmlBlockTextColor;
    margin: 10px 0;
    padding: 0;
    line-height: $htmlBlockTextLineHeight;
  }

  strong,
  b {
    margin: 0;
    color: $htmlBlockTextColor;
    font-weight: $htmlBlockTextFontWeight;
  }

  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    line-height: $htmlBlockTextLineHeight;
    font-size: $htmlBlockTextFontSizeMedium;
    border-left: 5px solid $htmlBlockTableColor;
  }

  img {
    max-width: 100%;
  }

  ul,
  ol {
    margin: 10px 0;
    padding: 0 0 0 30px;
  }

  table {
    border-color: $htmlBlockTableColor;
    hyphens: auto; // stylelint-disable-line plugin/no-unsupported-browser-features
  }

  th {
    padding: 5px 10px;
  }

  td {
    padding: 10px;
  }

  li {
    color: $htmlBlockTextColor;
  }

  a {
    color: $htmlBlockTextColor;
    text-decoration: underline;

    &:hover,
    &:visited {
      color: $htmlBlockTextColor;
      text-decoration: underline;
    }
  }

  &--no-padding {
    padding: 0 !important;
  }

  &--no-margin {
    margin: 0 !important;
  }

  &__content-section {
    margin: 15px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 30px 0;
      flex-direction: row;
    }
  }

  &__content-row--big {
    margin: 30px 0;

    @media (min-width: $screen-tablet-portrait) {
      margin: 40px 0;

      #{$root}__content-col {
        padding: 0 20px;
      }
    }
  }

  &__content-row {
    display: flex;
    flex-direction: column;

    @media (min-width: $screen-tablet-portrait) {
      flex-direction: row;
    }

    img {
      width: 100%;
    }
  }

  &__content-image {
    padding: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0;
    }

    > a {
      width: 100%;
    }
  }

  &__content-col {
    padding: 0 0 10px;

    @media (min-width: $screen-tablet-portrait) {
      padding: 0 10px;
      flex-grow: 1;
      flex-basis: 0;

      & > *:last-child {
        margin-bottom: 0;
      }
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &--full {
      width: 100%;
    }

    &--grow-1 {
      flex-grow: 1;
    }

    &--grow-2 {
      flex-grow: 2;
    }

    &--grow-3 {
      flex-grow: 3;
    }
  }

  &__content-buttons {
    .button {
      vertical-align: top;
    }

    .button:first-child {
      margin: 0 0 20px;

      @media (min-width: $screen-mobile-landscape) {
        margin: 0 20px 20px 0;
      }
    }
  }

  .lightbox & {
    > *:first-child {
      margin-top: 0;
    }
  }

  .ul {
    &--checkmark {
      padding: 0 0 0 10px;

      li {
        margin: 0 0 5px;
      }
    }

    &--checkmark-big {
      margin: 10px 0;
      padding: 0;
      list-style: none;

      @media (min-width: $screen-tablet-portrait) {
        margin: 20px 0;
      }

      li {
        padding: 0 0 0 40px;
        margin: 0 0 10px;
        position: relative;
        min-height: 33px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (min-width: $screen-tablet-portrait) {
          min-height: 50px;
          padding: 0 0 0 90px;
          margin: 0 0 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        @include iconFont('checkedBig') {
          position: absolute;
          color: inherit;
          left: 0;
          top: 3px;
          text-decoration: none;
          font-size: 22px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 42px;
          }
        }

        strong {
          font-size: 16px;

          @media (min-width: $screen-tablet-portrait) {
            font-size: 20px;
          }
        }

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  &__block--border {
    margin: 10px 0;
    padding: 20px;
    border: 1px solid $grayMediumColor;

    @media (min-width: $screen-tablet-portrait) {
      margin: 20px 0;
      padding: 40px;
    }
  }
}
